@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
    font-size: 18px;
	line-height: 24px;
}

a, i, p, span, u {
	font-size: 18px;
	line-height: 24px;
    font-family: 'PT Serif', serif;
    color: #000;
}
h1 {
	font-size:40px;
	letter-spacing:3px;
	text-transform:inherit;
	font-family: 'PT Serif', serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:32px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:32px;
	font-family: 'PT Serif', serif;
}
h3 {
	font-size:24px;
	font-weight:500;
	line-height:inherit;
	font-family: 'Open Sans', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Open Sans', sans-serif;
}
h6 {
	font-size:20px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h3, h3.product-name, h4, h5
{
	font-family: 'Open Sans', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 18px;
	color: #000;
	font-family: 'Open Sans', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#333;
	box-shadow:0 0 0 1px #e5e5e5;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #000 !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #000;
	font-family: 'PT Serif', serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #000;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span, .module-search .form-search .input-text::placeholder {
	color: #000;
	font-size: 18px;
	font-family: 'Open Sans', sans-serif;
}
.links > li > a:hover {
	background-color: #333333;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #000;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 20px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 20px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
    display: none;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #fff;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #6C092B !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 20px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #6C092B !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: inherit;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 40px;
	color: #000;
	letter-spacing: 3px;
	text-transform: inherit !important;
    font-family: 'PT Serif', serif !important;
    
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name a, h3.product-name a {
	font-size: 16px;
    color:#000;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #6C092B;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
    font-size: 20px;
    font-weight: 700;
    height: 45px !important;
    line-height: 45px !important;
    font-family: 'Open Sans', sans-serif;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #6C092B !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 20px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #E7E2D7;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #6C092B;
	color: #fff;
}
.footer-bottom {
    color:#fff;
}
.footer-bottom .item-left {
    text-align: center;
    float: none;
}
.footer-bottom .section {
    padding:0px;
}
.footer-bottom .footer-copyright {
    margin: 7px 0;
}

/************************** MICS **************************/

/*List elements*/
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul{
     list-style:disc;
     margin-left:25px;
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}

/*Inline search results*/

 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:500%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:25%;
         float:left;
    }
}
@media only screen and (max-width:959px) {
     #inline-search-results {
         width:590px;
    }
     #inline-search-results li.grow {
         width:25%;
         float:left;
    }
}
@media only screen and (max-width:768px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
@media only screen and (max-width:767px) {
    /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#000;
         padding:10px;
         font-style:normal;
    }
}
.inline-search-results-quickorder .highlight{
     color:#003DA5 !important
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
/*Change the text 'Switch Customer' to a FA icon for small width devices.*/

 .userSwitch a {
     text-indent:-9999px;
     position:absolute;
}
 .userSwitch a::after {
     content:"\f2c3";
}
 .userSwitch a::after {
     font-family:fontAwesome;
     font-size:22px;
     text-indent:0;
     float:left;
}

/*Change the account menu icon for mobile/tablet*/
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#000;
}
/*mini-cart*/
#mini-cart .feature-icon-hover > a{
     display:inline-flex;
}
#mini-cart .feature-icon-hover > a > span {
     padding:0 !important;
}
#mini-cart .feature-icon-hover > a > span, #mini-cart .feature-icon-hover > a > .empty {
     order:1;
}
#mini-cart .feature-icon-hover > a > .empty span.price {
     margin-top:0;
}
/*Footer*/
.footer-top-container {
     padding:25px 0;
}
@media only screen and (min-width:1260px) {
     .footer-top-container .section, .links-footer {
         display:flex;
    }
     .footer-top-container .section > div, .links-footer > div {
         margin:auto;
    }
}
@media only screen and (max-width:1259px) {
     .links-footer {
         display:flex;
    }
     .links-footer > div {
         margin:auto; 
         margin-left:0;
    }
     #footer .logo-column, #footer .phone-column {
         width:48% !important;
    }
     #footer .links-column {
         width:100%;
         margin-top:20px;
    }
     .phone-column h2 a {
         font-size:20px !important;
    }
}
@media only screen and (max-width:767px) {
     #footer .logo-column img {
         max-width:150px;
    }
     .footer-top-container {
         padding:15px 0;
    }
     #footer .links-footer {
         display:grid;
         grid-template-columns:auto auto auto;
         justify-content:space-around;
    }
}
.links-footer > div a {
     font-family:'Open Sans', sans-serif !important;
}
@media only screen and (min-width:1800px) {
     #footer .logo-column {
         width:15%;
    }
}
@media only screen and (min-width:1260px ) {
     .phone-column h2 a {
         font-size:28px;
    }
}
@media only screen and (min-width:1366px ) {
     .phone-column h2 a {
         font-size:32px;
    }
}
.fa-facebook{
     background:#000;
     color:#FFFFFF;
     padding:0.1em 0.3em;
     border-radius:50%;
}
.links-footer > div:hover,  {
     border-bottom:1px solid #000;
}

/*Header*/
#header .logo img {
     max-width:inherit;
}
.top-header-column {
     margin-top:5px;
     margin-bottom:30px;
}
.header-top-container {
     background-color:#fff;
     box-shadow:none;
}
.top-header-column .header-top.header, .top-header-column .header-top.header .module-header-multi {
     margin-left:0;
     margin-right:0;
}
.module-user-links .links {
     display:flex;
}
.module-user-links .links > li{
     margin:0 auto;
}
.module-user-links .links > li:last-child{
     margin-right:0;
}
.module-user-links .links .link-logout::before {
     content:"\f08b";
}
.module-user-links .links .link-admin::before {
     content:"\f013";
}
.module-user-links .links .link-account::before {
     content:"\f007";
}
.module-user-links .links .link-login::before {
     content:"\f090";
}
 .module-user-links .links .link-register::before {
     content:"\f007";
}
.module-user-links .links>li>a:before{
     font-family:fontAwesome;
     margin-right:8px;
     font-size:20px;
}
.links>li, .links>li>a {
     line-height:1em;
}
.links>li>a:hover {
     background-color:#fff;
     color:#000!important;
}
@media only screen and (min-width:1600px) {
     .header-links-column .item-user-links {
         width:23%;
    }
}
.has-admin-link .item-user-links{
     width:40% !important;
}
.cart-column {
     width:auto;
}
.cart-column .fa-shopping-cart {
     font-size:24px;
     margin-top:5px;
}
.module-search .form-search .button-search .fa {
     font-size:20px !important;
     color:#000 !important;
}
.module-search .form-search .input-text {
     border-radius:5px;
     border-color:#000;
     height:42px;
     line-height:40px;
     padding:7px 35px 5px 7px;
}
#nav {
     background-color:#6C092B ;
     margin-left:0
}
.nav-regular li.level0 > a > span {
     color:#fff !important;
     font-weight:700;
}
.nav-regular li.level0>a {
     text-transform:inherit;
}
#nav .nav-item.level0.parent>a .caret {
     border-top-color:#fff;
     opacity:1;
}
.category-column .nav-item--home {
     display:none;
}
@media only screen and (min-width:1280px) {
     .category-column ul#nav {
         display:flex;
    }
     .category-column ul#nav > li.level0{
         margin:0 auto;
    }
     .category-column ul#nav > li.level0.nav-1 {
         margin-left:0;
    }
     .nav-regular li.level0 > a > span {
         font-size:20px !important;
    }
}
@media only screen and (min-width:768px) and (max-width:1259px) {
     .links > li > a, .nav-regular li.level0 > a > span, .module-search .form-search .input-text::placeholder, .header a, .header p, .header span {
         font-size:16px !important;
    }
}
@media only screen and (max-width:1279px) {
     .header .logo {
         max-width:300px;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .header-logo-column {
         width:40% !important;
    }
     .header-links-column {
         width:58% !important;
    }
     .header-links-column .phone-column {
         width:30%;
    }
     #header {
         border-bottom:1px solid #000;
         padding-bottom:10px;
    }
     .header-links-column .fa-bars, .header-links-column .mh-account-btn .fa-vcard {
         font-size:20px !important;
         padding-top:2px;
    }
}
.mobile-header .module-header-multi {
     display:flex;
}
 .mobile-header .module-header-multi > div {
     margin:auto;
     width:inherit;
}
.mobile-header {
     margin-bottom:0 !important;
     border-bottom:1px solid #000;
}
.mobile-header .logo img {
     max-width:120px !important;
}
.mobile-header .fa.fa-shopping-cart {
     top:0;
     left:0;
}
.mobile-header .feature-icon-hover .empty {
     display:none;
}
/*******/
/*category pages*/
.product-search-container {
     width:100%;
     margin-left:0;
     margin-right:0;
}
.sidebar-wrapper.module-category-filters .block-title span {
     text-transform:Capitalize;
}
.sidebar-wrapper.module-category-filters .block-title, .accordion-style1 li a {
     border:none;
}
.product-listing {
     margin-top:0 !important;
}
.pager {
     border:none;
}
.pager .pages li a{
     background-color:rgb(231, 226, 215);
     color:#000;
}
.pager .pages li.current {
     background-color:rgb(231, 226, 215);
     color:#000;
}
.pager .pages li a:hover {
     background-color:rgb(231, 226, 215);
}
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
}
@media only screen and (max-width:767px) {
     .products-grid .item button.button span span {
         padding:0 12px !important;
    }
}
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
.category-products-grid .item {
     border:none;
     margin-bottom:70px;
}
.category-products-grid .item .qty-box {
     height:40px;
     margin-bottom:10px;
}
button.more-info span {
     background-color:rgb(231, 226, 215);
     color:#000;
}
button.more-info:hover span {
     background-color:rgb(231, 226, 215) !important;
     color:#000;
}
/*List elements*/
.page-content ul, #product-tabs .std ul {
     list-style:disc;
     margin-left:25px;
}
